module.exports = {
  "IDMClientOptions": {
    "externalAuth": false,
    "cookiesEndpoint": "https://auth-stage.bccsa-services.ca",
    "apiEndpoint": "https://api-auth-stage.bccsa-services.ca",
    "roles": {
      "USER": "59a72bbd-9a43-427e-9c92-afd74ab19431",
      "ADMIN": "MY-APP-ADMIN-ROLE",
      "ROOT": "59a72bbd-9a43-427e-9c92-afd74ab19433"
    },
    "partitions": {
      "PERSONAL": "ca.bccsa.personal",
      "PROFESSIONAL": "ca.bccsa.professional"
    }
  },
  "IncludesStageOnName": true,
  "Stage": "stage",
  "ApplicationRecaptchaKey": "6LfO17sUAAAAADbEcJD9brTVgjw5uPW5h495bHwt",
  "ApplicationAdvancedSearchDisabled": false,
  "ApplicationRoutes": {
    "login": "/",
    "homepage": "/",
    "register": "/register",
    "registrationConfirmation": "/registrationConfirmation/:id/:id2",
    "registrationConfirmationMail": "/registrationConfirmation/:id",
    "registrationConfirmationReset": "/registrationConfirmation/reset/:id",
    "resetPassword": "/resetPass/:id",
    "resetPasswordRaw": "/resetPass/",
    "resetPasswordConfirmation": "/resetPass/confirm/:id",
    "setupPassword": "/setupPass/:id/:id2",
    "user": "/user/:id",
    "profile": "/user",
    "users": "/users",
    "applications": "/apps",
    "application": "/app/:id",
    "role": "/app/:id/role/:id2",
    "accessToken": "/app/:id/accessToken/:id2",
    "partitionACLs": "/acls",
    "partitionACL": "/acl/:id",
    "authError": "/authError",
    "smsMFA": "/smfa",
    "contactAdmin": "mailto:support@ikonintegration.com"
  },
  "ThemeDomainsMapping": {
    "*.bccsa-services.ca": {
      "themeKey": "bccsa",
      "adminRole": "b8807432-667_BC___Students_Portal",
      "userRole": "d11b5145-69f_BC___Students_Portal",
      "idmKey": "3226bcfa-de85-4074-9b8e-9f5335c85bdc",
      "idmSecret": "1b3a37c2-1c67-471b-b7d5-619a49db21b3",
      "idmExternalAuthDomain": "auth-stage.bccsa-services.ca"
    },
    "*.constructionsafety.ca": {
      "themeKey": "csam",
      "adminRole": "ee6f6a95-69f_MB___Students_Portal",
      "userRole": "b0176ff6-75a_MB___Students_Portal",
      "idmKey": "8ad504ec-1d14-4143-8e87-0b42e5e3c994",
      "idmSecret": "4a959f1e-330f-4ada-a138-b9e42ff3e7fe",
      "idmExternalAuthDomain": "auth-stage.constructionsafety.ca"
    }
  },
  "DatadogOptions": {
    "applicationId": "a8039f15-8b00-4cae-86af-e5f95cd24ece",
    "clientToken": "pub1e0e6e2f2186d5b5c5d0b66627a2481f",
    "site": "datadoghq.com",
    "service": "idm-fe",
    "env": "stage",
    "sampleRate": 100,
    "trackInteractions": true,
    "defaultPrivacyLevel": "allow"
  }
}